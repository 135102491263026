@import '../../node_modules/react-toastify/dist/ReactToastify.min.css';

.Toastify {
  /* Modal default z-index is 9999 */
  z-index: 99999;
}

.Toastify__toast {
  border-radius: 6px;
  --toastify-color-progress-info: #3077c9;
  --toastify-color-progress-success: #5ad69f;
  --toastify-color-progress-warning: #f1c40f;
  --toastify-color-progress-error: #d14734;
}

/* --plain */
.Toastify__toast.Toastify__toast--plain {
  display: flex;
  flex-direction: column;
  padding: 0;
  border-radius: 6px;
}
.Toastify__toast-body.Toastify__toast-body--plain {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
  padding: 0;
  align-items: stretch;
}
.Toastify__toast-body > div:last-child {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
}

@media screen and (max-width: 768px) {
  .Toastify__toast.Toastify__toast--plain {
    border-radius: 0px;
  }
  .Toastify__toast-container.Toastify__toast-container--wide,
  .Toastify__toast-container.Toastify__toast-container--wide .Toastify__toast {
    width: 100%;
  }
}

@media screen and (min-width: 769px) {
  /* wide container support */
  .Toastify__toast-container {
    --toastify-toast-max-width: 416px;
    --toastify-toast-min-width: var(--toastify-toast-width);
  }
  .Toastify__toast-container.Toastify__toast-container--wide {
    width: var(--toastify-toast-max-width);
  }
  .Toastify__toast-container.Toastify__toast-container--wide .Toastify__toast {
    width: fit-content;
    min-width: var(--toastify-toast-min-width);
  }
}
