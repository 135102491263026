@import 'react-datepicker/dist/react-datepicker.css';

.react-datepicker {
  border: 1px solid rgba(1, 39, 84, 0.05);
  box-shadow:
    0 8px 10px -6px rgba(30, 30, 30, 0.1),
    0 20px 25px -5px rgba(30, 30, 30, 0.1);
  border-radius: 8px;
  padding: 24px 10px 10px;
}

.react-datepicker-popper {
  z-index: 9;
  animation: popper-appear 0.2s ease-in-out forwards;
}

@keyframes popper-appear {
  from {
    opacity: 0;
    margin-top: -10px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__navigation {
  background: #f2f4f6;
  border-radius: 8px;
}

/* Override hover styles on touch devices */
@media (hover: none) {
  .react-datepicker__navigation:hover *::before {
    border-color: #345276;
  }
}

.react-datepicker__navigation.react-datepicker__navigation--previous {
  top: 24px;
  left: 24px;
}
.react-datepicker__navigation.react-datepicker__navigation--next {
  top: 24px;
  right: 24px;
}

.react-datepicker__navigation-icon:before {
  height: 6px;
  width: 6px;
  border-color: #345276;
  border-width: 2px 2px 0 0;
}

.react-datepicker__navigation-icon--previous {
  top: 7px;
  left: -3px;
}
.react-datepicker__navigation-icon--next {
  top: 7px;
  left: 3px;
}

.react-datepicker__header {
  background-color: transparent;
  border: none;
  padding: 0 14px;
}

.react-datepicker__current-month {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  margin-bottom: 20px;
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #012754;
}

.react-datepicker__day-names .react-datepicker__day-name,
.react-datepicker__day {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  text-align: center;
}

.react-datepicker__day-names .react-datepicker__day-name {
  color: #5a7390;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 32px;
  line-height: 32px;
}

.react-datepicker__day {
  color: #345276;
}

.react-datepicker__day--disabled {
  color: #a6b3c3;
}

.react-datepicker__day.react-datepicker__day--selected,
.react-datepicker__day.react-datepicker__month-text--selected,
.react-datepicker__day.react-datepicker__quarter-text--selected,
.react-datepicker__day.react-datepicker__year-text--selected {
  background: #3077c9;
  border-radius: 8px;
  color: #ffffff;
  font-weight: 700;
}

.react-datepicker__day.react-datepicker__day--keyboard-selected,
.react-datepicker__day.react-datepicker__month-text--keyboard-selected,
.react-datepicker__day.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__day.react-datepicker__year-text--keyboard-selected {
  background: #f2f4f6;
  border-radius: 8px;
  color: #012754;
  font-weight: 700;
}
