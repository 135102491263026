#react-select-static-dropdown-id-listbox,
#react-select-static-multiselect-id-listbox {
  animation: appear 0.2s ease-in-out forwards;
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
