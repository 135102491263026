/**
 * @description: Animating 3 dots
 */
.loading-3-dots::before {
  content: '...';
  display: inline-block;
  clip-path: inset(0 100% 0 0);
  animation: loading-3-dots-animation 1s steps(4) infinite;
}
@keyframes loading-3-dots-animation {
  to {
    clip-path: inset(0 -30% 0 0);
  }
}

/**
 * @description: Animating fake progress bar
 */
.loading-progress-line {
  position: relative;
  height: 2px;
  background-color: #e6e9ee;
  border-radius: 9999px;
}
.loading-progress-line::before {
  content: '';
  position: absolute;
  inset: 0;
  background-color: #3077c9;
  border-radius: 9999px;
  transform: scaleX(0);
  transform-origin: 0 50%;
  animation: loading-progress-line-animation 5s ease-out forwards;
}
@keyframes loading-progress-line-animation {
  to {
    transform: scaleX(1);
  }
}
