@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* Typefaces */
  .typeface-1-heading {
    @apply font-inter leading-[1.3] font-medium;
  }
  .typeface-2-heading {
    @apply font-dm-serif leading-small;
  }
  .typeface-body {
    @apply font-dm-sans leading-normal;
  }
  .typeface-buttons {
    @apply font-dm-sans font-medium;
  }

  /* Specific */
  .font-heading-1-h0 {
    @apply typeface-1-heading text-5xl;
  }
  .font-heading-1-h1 {
    @apply typeface-1-heading text-3.5xl;
  }
  .font-heading-1-h2 {
    @apply typeface-1-heading text-2.5xl;
  }
  .font-heading-1-h3 {
    @apply typeface-1-heading text-2xl;
  }
  .font-heading-1-h4 {
    @apply typeface-1-heading text-xl;
  }
  .font-heading-1-h5 {
    @apply typeface-1-heading text-base;
  }
  .font-heading-1-h6 {
    @apply typeface-1-heading text-sm;
  }

  .font-heading-2-h0 {
    @apply typeface-2-heading sm:text-5xl sm:leading-small text-4.5xl;
  }
  .font-heading-2-h1 {
    @apply typeface-2-heading text-3.5xl;
  }
  .font-heading-2-h2 {
    @apply typeface-2-heading text-2.5xl;
  }
  .font-heading-2-h3 {
    @apply typeface-2-heading text-2xl;
  }
  .font-heading-2-h4 {
    @apply typeface-2-heading text-xl;
  }
  .font-heading-2-h5 {
    @apply typeface-2-heading text-base;
  }
  .font-heading-2-h6 {
    @apply typeface-2-heading text-sm;
  }

  .font-body-1 {
    @apply typeface-body text-xl;
  }
  .font-body-2 {
    @apply typeface-body text-base;
  }
  .font-body-3 {
    @apply typeface-body text-sm;
  }
  .font-body-4 {
    @apply typeface-body text-xs;
  }

  .font-button-1 {
    @apply typeface-buttons text-lg leading-[1.3];
  }
  .font-button-2 {
    @apply typeface-buttons text-base leading-4;
  }
  .font-button-3 {
    @apply typeface-buttons text-xs leading-[1.3];
  }
}
