@import '../../node_modules/react-image-gallery/styles/css/image-gallery.css';

.image-gallery-slide-wrapper {
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-gallery-bullets {
  bottom: -20px;
}

.image-gallery-bullets .image-gallery-bullet {
  margin: 0px 4px;
  padding: 4px;
  box-shadow: none;
  background-color: #ccd4dd;
  border: none;
}

.image-gallery-bullets .image-gallery-bullet.active {
  background-color: #012754;
  border: none;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  border-radius: 8px;
  max-height: 240px;
}

.image-gallery-slide::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(1, 39, 84, 0.03);
}
