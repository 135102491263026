/* slide-up */
[class*='slide-up-'] {
  --duration: 0.5s;
  --delay: 0s;
  --distance: 15px;
  --easing: ease-in-out;
}
.slide-up-appear,
.slide-up-enter {
  opacity: 0;
  transform: translateY(var(--distance));
}
.slide-up-appear-active,
.slide-up-enter-active,
.slide-up-exit {
  opacity: 1;
  transform: none;
}
.slide-up-exit-active {
  opacity: 0;
  transform: translateY(calc(var(--distance) * -1));
}
.slide-up-appear-active,
.slide-up-enter-active,
.slide-up-exit-active {
  transition:
    opacity var(--duration) var(--delay),
    transform var(--duration) var(--easing) var(--delay);
}

/* slide-down */
[class*='slide-down-'] {
  --duration: 0.5s;
  --delay: 0s;
  --distance: 15px;
  --easing: ease-in-out;
}
.slide-down-appear,
.slide-down-enter {
  opacity: 0;
  transform: translateY(calc(var(--distance) * -1));
}
.slide-down-appear-active,
.slide-down-enter-active,
.slide-down-exit {
  opacity: 1;
  transform: none;
}
.slide-down-exit-active {
  opacity: 0;
  transform: translateY(var(--distance));
}
.slide-down-appear-active,
.slide-down-enter-active,
.slide-down-exit-active {
  transition:
    opacity var(--duration) var(--delay),
    transform var(--duration) var(--easing) var(--delay);
}

/* slide-left */
[class*='slide-left-'] {
  --duration: 0.5s;
  --delay: 0s;
  --distance: 15px;
  --easing: ease-in-out;
}
.slide-left-appear,
.slide-left-enter {
  opacity: 0;
  transform: translateX(var(--distance));
}
.slide-left-appear-active,
.slide-left-enter-active,
.slide-left-exit {
  opacity: 1;
  transform: none;
}
.slide-left-exit-active {
  opacity: 0;
  transform: translateX(calc(var(--distance) * -1));
}
.slide-left-appear-active,
.slide-left-enter-active,
.slide-left-exit-active {
  transition:
    opacity var(--duration) var(--delay),
    transform var(--duration) var(--easing) var(--delay);
}

/* slide-right */
[class*='slide-right-'] {
  --duration: 0.5s;
  --delay: 0s;
  --distance: 15px;
  --easing: ease-in-out;
}
.slide-right-appear,
.slide-right-enter {
  opacity: 0;
  transform: translateX(calc(var(--distance) * -1));
}
.slide-right-appear-active,
.slide-right-enter-active,
.slide-right-exit {
  opacity: 1;
  transform: none;
}
.slide-right-exit-active {
  opacity: 0;
  transform: translateX(var(--distance));
}
.slide-right-appear-active,
.slide-right-enter-active,
.slide-right-exit-active {
  transition:
    opacity var(--duration) var(--delay),
    transform var(--duration) var(--easing) var(--delay);
}

/* switch-right */
.switch-right-appear,
.switch-right-enter {
  opacity: 0;
  transform: translate(15px);
}
.switch-right-exit {
  opacity: 1;
  transform: none;
}
.switch-right-enter-active {
  opacity: 1;
  transform: none;
}
.switch-right-exit-active {
  opacity: 0;
  transform: scale(0.9);
}
.switch-right-appear-active,
.switch-right-enter-active,
.switch-right-exit-active {
  transition: all 0.3s ease-in-out;
}

/* switch-left */
.switch-left-appear,
.switch-left-enter {
  opacity: 0;
  transform: translate(-15px);
}
.switch-left-exit {
  opacity: 1;
  transform: none;
}
.switch-left-enter-active {
  opacity: 1;
  transform: none;
}
.switch-left-exit-active {
  opacity: 0;
  transform: scale(0.9);
}
.switch-left-appear-active,
.switch-left-enter-active,
.switch-left-exit-active {
  transition: all 0.3s ease-in-out;
}

/* fade */
[class*='fade-'] {
  --duration: 0.5s;
  --easing: ease-out;
  --delay: 0s;
}
.fade-appear,
.fade-enter {
  opacity: 0;
}
.fade-exit {
  opacity: 1;
}
.fade-appear-active,
.fade-enter-active {
  opacity: 1;
}
.fade-exit-active,
.fade-exit-done {
  opacity: 0;
}
.fade-appear-active,
.fade-enter-active,
.fade-exit-active {
  transition: opacity var(--duration) var(--easing) var(--delay);
}

/* zoom-in */
[class*='zoom-in-'] {
  --duration: 0.3s;
  --delay: 0s;
  --max-scale: 0.4;
  --easing: ease-out;
}
.zoom-in-appear,
.zoom-in-enter {
  opacity: 0;
  transform: scale(var(--max-scale));
}
.zoom-in-exit {
  transform: scale(1);
  opacity: 1;
}
.zoom-in-appear-active,
.zoom-in-enter-active {
  transform: scale(1);
  opacity: 1;
}
.zoom-in-exit-active {
  transform: scale(var(--max-scale));
  opacity: 0;
}
.zoom-in-appear-active,
.zoom-in-enter-active,
.zoom-in-exit-active {
  transition:
    opacity var(--duration) ease-in-out var(--delay),
    transform var(--duration) var(--easing) var(--delay);
}
/* zoom-out */
[class*='zoom-out-'] {
  --duration: 0.3s;
  --delay: 0s;
  --max-scale: 1.2;
  --easing: ease-out;
}
.zoom-out-appear,
.zoom-out-enter {
  opacity: 0;
  transform: scale(var(--max-scale));
}
.zoom-out-exit {
  transform: scale(1);
  opacity: 1;
}
.zoom-out-appear-active,
.zoom-out-enter-active {
  transform: scale(1);
  opacity: 1;
}
.zoom-out-exit-active {
  transform: scale(var(--max-scale));
  opacity: 0;
}
.zoom-out-appear-active,
.zoom-out-enter-active,
.zoom-out-exit-active {
  transition:
    opacity var(--duration) ease-in-out var(--delay),
    transform var(--duration) var(--easing) var(--delay);
}

/* expand-horizontal */
[class*='expand-horizontal-'] {
  --width: 1000px;
  --duration: 0.5s;
}
.expand-horizontal-enter {
  opacity: 0;
  max-width: 0;
  overflow: hidden;
}
.expand-horizontal-exit {
  opacity: 1;
  max-width: var(--width);
  overflow: hidden;
}
.expand-horizontal-enter-active {
  opacity: 1;
  max-width: var(--width);
  overflow: hidden;
  transition:
    max-width var(--duration) cubic-bezier(0.66, 0, 0.66, 0.33),
    opacity var(--duration) linear;
}
.expand-horizontal-exit-active {
  opacity: 0;
  max-width: 0;
  overflow: hidden;
  transition:
    max-width var(--duration) cubic-bezier(0.33, 0.66, 0.33, 1),
    opacity var(--duration) linear;
}

/* pop-in */
[class*='pop-in-'] {
  --duration: 0.5s;
  --delay: 0s;
  --easing: cubic-bezier(0.3, 1.5, 0.3, 0.92);
  --dir: 1; /* -1 - left, 1 - right */
  --rotate-deg: 30deg;
  --skewX: calc(var(--dir) * var(--rotate-deg) * -5);
}
.pop-in-appear,
.pop-in-enter {
  opacity: 0;
  transform: scale(0) rotateZ(calc(var(--dir) * var(--rotate-deg))) skewX(var(--skewX));
}
.pop-in-exit {
  transform: scale(1);
  opacity: 1;
}
.pop-in-appear-active,
.pop-in-enter-active {
  transform: scale(1);
  opacity: 1;
}
.pop-in-exit-active {
  transform: scale(0) rotateZ(calc(-1deg * var(--dir) * var(--rotate-deg))) skewX(var(--skewX));
  opacity: 0;
}
.pop-in-appear-active,
.pop-in-enter-active,
.pop-in-exit-active {
  transition:
    opacity calc(var(--duration) / 2) linear var(--delay),
    transform var(--duration) var(--easing) var(--delay);
}
.pop-in-enter-done {
  opacity: 1;
}
.pop-in-appear-done {
  opacity: 1;
}
