html,
body,
#__next {
  width: 100%;
  /* To smooth any scrolling behavior */
  -webkit-overflow-scrolling: touch;
  margin: 0px;
  padding: 0px;
  /* Allows content to fill the viewport and go beyond the bottom */
  /* If vh units are not supported it will take 100% as a fallback */
  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  min-height: calc(100% + (var(--safe-area-inset-top) + var(--safe-area-inset-bottom)));
  min-height: calc(100vh + (var(--safe-area-inset-top) + var(--safe-area-inset-bottom)));
}
#__next {
  flex-shrink: 0;
  flex-basis: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  flex: 1;
}

html {
  scroll-behavior: smooth;
  /* Prevent text size change on orientation change https://gist.github.com/tfausak/2222823#file-ios-8-web-app-html-L138 */
  -webkit-text-size-adjust: 100%;

  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  height: calc(100% + (var(--safe-area-inset-top) + var(--safe-area-inset-bottom)));
}

body {
  display: flex;
  /* Allows you to scroll below the viewport; default value is visible */
  overflow-y: auto;
  overscroll-behavior-y: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: scrollbar;
  color: #012754;
}

body.lock-scroll {
  overflow: hidden;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
}

button:active {
  opacity: 0.5;
}

button:disabled {
  cursor: not-allowed;
}

:focus-visible {
  outline: #2964a8 auto 2px;
}

.react-loading-skeleton {
  --base-color: #f2f4f6;
  --highlight-color: #fafbfc;
}
